import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { NgxCurrencyConfig } from 'ngx-currency';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { CustomCurrencyMaskConfig } from 'src/app/shared/config/currency-mask.config';
import { ContributionFormService } from '../../../../contribution-request/services/contribution-form.service';
import { InvestmentRequestFormService } from '../../../../services/investment-request-form.service';
import { RequestTypeEnum } from '../../../enums/account-update.enum';
import { TradeModelIdEnum } from '../../../enums/trade-model.enum';
import { AllocationFormGroup, AllocationCustomHoldingSleeveFormArray, ProtectedCashFormGroup, NewSleeveFormGroup, AllocationSleeveFormGroup } from '../../../models/allocation-form.models';
import { allocationAmountNotEqual } from '../../../validators/allocation-amount-equal.validator';
import { noAllocationSet } from '../../../validators/allocation-minimum-entries.validator';
import { needsCustomHoldingAllocation } from '../../../validators/custom-allocation-minimum.validator';
import { Dollar, Percent } from '../../../constants/allocation-action.constant';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-allocation-input',
  templateUrl: './allocation-input.component.html'
})
export class AllocationInputComponent implements OnInit, OnDestroy {
  @Input() dialogRef?: DialogRef;
  @Input() form?: AllocationFormGroup;
  @Input() initialAmount?: number;
  @Input() customHoldingAllocations?: AllocationCustomHoldingSleeveFormArray;
  @Input() requestType?: RequestTypeEnum;
  @Input() investmentRequestFormService?: InvestmentRequestFormService;
  @Input() contributionRequestFormService?: ContributionFormService;
  Dollar = Dollar;
  Percent = Percent;
  hasCashSleeve = false;
  errorRequired = 'required';
  remainingBalance?: number | undefined;
  requestTypeEnum = RequestTypeEnum;
  sleeveBalanceSubscription?: Subscription;
  newSleeveAmountBalanceSubscription?: Subscription;
  newSleevePercentageBalanceSubscription?: Subscription;
  protectedCashBalanceSubscription?: Subscription;
  accountTotalFeeMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      prefix: '',
      suffix: '%',
      min: 0,
      max: 100,
      precision: 3
    }
  };

  sleevePercentageMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      decimal: '.',
      precision: 2,
      prefix: '',
      suffix: '%',
      min: 0,
      max: 100,
    }
  };

  allocationInputOptions = [
    { name: 'Re-allocate account balance to all models', value: false },
    { name: 'Make trades in custom holdings model only', value: true }
  ];

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.checkCashSleeve();
    this.calculateRemainingBalance();
    this.setupValueChangeDetection();
  }

  ngOnDestroy(): void {
    this.unsubscribeChangeDetection();
  }

  ResetForm(): void {
    //hide error panel
    this.investmentRequestFormService ? this.investmentRequestFormService.showValidationErrorPanel = false : null;

    //remove all custom holding objects in array
    this.customHoldingAllocations?.clear();

    //set all sleeve values to null values
    this.sleevesFormArray.forEach((sleeve) => {
      sleeve.patchValue({ amount: null });
    });

    //set new sleeve object to null values;
    this.form?.controls?.newSleeve.patchValue({ amount: null, percentage: null });
  }

  setupValueChangeDetection(): void {
    this.sleeveBalanceSubscription = this.form?.controls.sleeves.valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.calculateRemainingBalance();
      });

    this.newSleeveAmountBalanceSubscription = this.form?.controls?.newSleeve?.controls?.amount.valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.calculateRemainingBalance();
      });

    this.newSleevePercentageBalanceSubscription = this.form?.controls?.newSleeve?.controls?.percentage.valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.calculateRemainingBalance();
      });

    this.protectedCashBalanceSubscription = this.form?.controls?.protectedCashAmount?.valueChanges.pipe(distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.calculateRemainingBalance();
      });
  }

  unsubscribeChangeDetection(): void {
    this.sleeveBalanceSubscription?.unsubscribe();
    this.newSleeveAmountBalanceSubscription?.unsubscribe();
    this.newSleeveAmountBalanceSubscription?.unsubscribe();
  }

  get protectedCashForm(): ProtectedCashFormGroup | undefined {
    return this.form?.controls.protectedCashAmount;
  }

  get protectedCashAmount(): number {
    return this.form?.value?.protectedCashAmount?.amount || 0;
  }

  get useProtectedCash(): boolean {
    return this.form?.value.useProtectedCash || false;
  }

  get protectedCashValueRequired(): boolean {
    return (this.protectedCashForm?.controls.amount.hasError(this.errorRequired) || this.protectedCashForm?.controls.fee.hasError(this.errorRequired)) || false;
  }

  get currentAllocationAmount(): number {
    const sleeveValues = this.form?.controls.sleeves?.value.reduce((acc, current) => acc + current.amount, 0) || 0;
    const newSleeveValue = this.form?.controls?.newSleeve?.controls?.amount?.value as number || 0;
    return sleeveValues + newSleeveValue + this.protectedCashAmount || 0;
  }

  // get sleevesFormArray(): AllocationSleeveFormArray {
  //   return this.form?.controls.sleeves as AllocationSleeveFormArray;
  // }

  get sleevesFormArray(): AllocationSleeveFormGroup[] {
    if (this.form?.value.isTradesOnly){
      return this.form?.controls.sleeves.controls.filter(x => x.value.isCustomSleeve) as AllocationSleeveFormGroup[];
    } else {
      return this.form?.controls.sleeves.controls as AllocationSleeveFormGroup[];
    }
  }

  get useCurrentSetup(): UntypedFormControl {
    return this.form?.controls.useCurrentSetup as UntypedFormControl;
  }

  //omitted 4/11/23 - This could unintentionally have negative impact on the advisors model splits, thus impacting the accounts risk level as well. - cody
  /*get sellAllEvenly(): UntypedFormControl {
    return this.form?.controls.sellAllEvenly as UntypedFormControl;
  }*/

  get needsCustomHoldingAllocation(): boolean {
    return this.form?.hasError(needsCustomHoldingAllocation) || false;
  }

  get allocationAmountNotEqual(): boolean {
    return this.form?.hasError(allocationAmountNotEqual) || false;
  }

  get noAllocationSet(): boolean {
    return this.form?.hasError(noAllocationSet) || false;
  }

  get newSleeveInvalid(): boolean {
    return this.form?.controls?.newSleeve?.invalid || false;
  }

  get formTouched(): boolean {
    return this.form?.touched || false;
  }

  get formHasError(): boolean {
    return this.form?.invalid || false;
  }

  get hasCustomHoldings(): boolean {
    return !!this.sleevesFormArray.find(s => s.value.isCustomSleeve);
  }

  get hasOnlyCustomHoldings(): boolean {
    return this.sleevesFormArray.length <= 1 && !!this.sleevesFormArray.find(s => s.value.isCustomSleeve);
  }

  calculateRemainingBalance(): void {
    if (this.initialAmount) {
      this.remainingBalance = this.initialAmount - Number(this.currentAllocationAmount.toFixed(2));
      this.calculateSleeveBalance();
    }  else {
      this.remainingBalance = undefined;
    }

  }

  calculateSleeveBalance(): void {
    if (this.requestType === this.requestTypeEnum.reallocation) {

      let sleeveSum = 0;
      const sleeveCount = this.sleevesFormArray.length;

      this.sleevesFormArray.forEach((sleeve) => {
        let sleeveAmount = null;
        let sleevePercentage = null;

        if (this.form?.controls.type.value === Dollar) {
          sleeveAmount = sleeve.value.amount ? sleeve.value.amount : null;
          sleevePercentage = this.initialAmount && sleeveAmount ? (sleeveAmount / this.initialAmount) * 100 : null;
          sleeve.patchValue({ amount: sleeveAmount, percentage: sleevePercentage });
        }
        if (this.form?.controls.type.value === Percent) {
          sleevePercentage = sleeve.value.percentage ? (sleeve.value.percentage) : null;
          sleeveAmount = this.initialAmount && sleeve.value.percentage ? this.initialAmount * (sleeve.value.percentage / 100) : 0;
          sleeveSum = sleeveSum + Number(sleeveAmount?.toFixed(2));

          // if the splits by percentage are not even (but remainder is greater than -.01 or less than than .01),
          // we'll calculate the last item's value in the sleeve list with the remainder accordingly
          const remainder = this.initialAmount ? (this.initialAmount - sleeveSum) : 0;
          if (sleeveCount > 1 && remainder > -.01 && remainder < .01) {
            sleeve.patchValue({ amount: sleeveAmount ? Number((sleeveAmount + remainder).toFixed(2)) : null, percentage: sleevePercentage });
          } else {
            sleeve.patchValue({ amount: sleeveAmount ? Number(sleeveAmount.toFixed(2)) : null, percentage: sleevePercentage });
          }
        }
      });

      let newSleevePercentage = null;
      let newSleeveAmount = null;
      if (this.form?.controls?.newSleeve) {
        if (this.form?.controls.type.value === Dollar) {
          newSleeveAmount =  this.form?.controls?.newSleeve.value.amount ?  this.form?.controls?.newSleeve.value.amount : null;
          newSleevePercentage = this.initialAmount &&  this.form?.controls?.newSleeve.value.amount ? ( this.form?.controls?.newSleeve.value.amount / this.initialAmount) * 100 : null;
          this.form?.controls?.newSleeve.patchValue({ amount: newSleeveAmount, percentage: newSleevePercentage });
        }
        if (this.form?.controls.type.value === Percent) {
          newSleevePercentage =  this.form?.controls?.newSleeve.value.percentage ?  this.form?.controls?.newSleeve.value.percentage : null;
          newSleeveAmount = this.initialAmount && this.form?.controls?.newSleeve.value.percentage ? this.initialAmount * (this.form?.controls?.newSleeve.value.percentage / 100) : null;

          // if the splits by percentage are not even (but remainder greater than -.01 or less than than .01) between new sleeve and sleeves in sleeve list,
          // we'll calculate the new sleeve item's value with the remainder accordingly
          const remainder = this.initialAmount ? this.initialAmount - (sleeveSum + Number(newSleeveAmount)) : 0;
          if (remainder > -.01 && remainder < .01) {
            this.form?.controls?.newSleeve.patchValue({ amount: newSleeveAmount ? Number((newSleeveAmount + remainder).toFixed(2)) : null, percentage: newSleevePercentage });
          } else {
            this.form?.controls?.newSleeve.patchValue({ amount: newSleeveAmount ? Number(newSleeveAmount.toFixed(2)) : null, percentage: newSleevePercentage });
          }
        }
      }
    }
  }

  checkCashSleeve(): void {
    if (this.sleevesFormArray.find(sleeve => sleeve.value.id === TradeModelIdEnum.Cash)) this.hasCashSleeve = true;
    else this.hasCashSleeve = false;
  }

  removeSleeve(index: number): void {
    this.investmentRequestFormService?.removeSleeve(index);
  }

  showCustomHoldings(): void {
    this.form?.controls.showCustomHoldings.setValue(true);
  }

  addNewSleeve(sleeve: NewSleeveFormGroup, showCustomHoldings: boolean): void {
    if (showCustomHoldings && sleeve.value.isCustomHoldings) {
      this.showCustomHoldings();
    }
    this.investmentRequestFormService?.addSleeve(sleeve);
    sleeve.reset();
  }
}