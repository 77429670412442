<div class="z-10 flex flex-col items-center justify-center p-20 m-auto lg:gap-y-40 gap-y-20 w-460">
  <img class="w-auto h-48 lg:h-72" src="assets/images/logos/alphastar-logo-light-bg.svg">
  <div class="flex flex-col text-center gap-y-20">
    <!-- <h4 class="text-xl font-bold lg:text-3xl">Welcome to a brand new Portal experience!</h4> -->
    <div class="lg:text-lg">
      <p>Please sign in below.</p>
    </div>
  </div>
  <form [formGroup]="loginForm" class="flex flex-col w-full gap-y-20" (submit)="onLoginSubmit()">
    <div class="flex flex-col flex-shrink-0">
      <label class="mb-5 label" for="userNameOrEmail">Username or Email</label>
      <input type="text" name="userNameOrEmail" formControlName="userNameOrEmail" autofocus class="input is-lg">
    </div>
    <div class="flex flex-col flex-shrink-0">
      <label class="mb-5 label" for="password">Password</label>
      <input type="password" name="password" formControlName="password" class="input is-lg">
    </div>
    <div class="flex flex-col flex-none mt-20 gap-y-20">
      <button class="text-base button is-lg is-primary focus:ring-0" mat-button type="submit">Sign In</button>
      <!-- <a href="#" class="block my-20 text-base text-center underline mx-30">Need help signing in?</a> -->
    </div>
    <!-- <div *ngIf="locked" class="mx-48 mb-10 font-normal text-center text-md text-red"> TODO: once server side implements lockout policy
    This account has been locked due to too many failed login attempts. It will be unlocked at {{lockedUntil | date}}.
  </div> -->
  @if (disabled) {
    <div class="font-normal text-center text-red">
      Access Denied: Please verify your account information with your ACM team.
    </div>
  }
  @if (invalidCredentials) {
    <div class="font-normal text-center text-md text-red">
      Please verify that you have entered the correct credentials.
    </div>
  }
  <div>
    <a [routerLink]="['/password-reset-request']"><p class="font-normal text-center cursor-pointer text-md">Need help signing in?</p> </a>
  </div>
</form>
<app-copyright></app-copyright>
</div>