@if (parentForm) {
  <section class="flex flex-col gap-y-30" [formGroup]="parentForm">
    <p class="font-bold">Custom Holdings Allocation: <span class="text-blue-dark">{{customHoldingsAmount | currency}}</span></p>
    @if (requestType === requestTypeEnum.distribution) {
      <mat-checkbox formControlName="sellAllCustomHoldings">Sell All
      shares of all positions</mat-checkbox>
    }
    @if (!parentForm.value.sellAllCustomHoldings) {
      <div class="flex flex-col gap-y-10" formArrayName="allocation">
        <div class="flex text-xs font-semibold uppercase gap-x-10 text-gray">
          <span class="flex-none w-64"></span>
          <span class="flex-grow">Actions</span>
          <span class="w-96">Stock Symbols / Fund Code</span>
          <span class="w-96">Amount</span>
          <span class="w-34"></span>
        </div>
        <!-- LIST -->
        @for (item of customAllocationFormArray?.controls; track item; let i = $index) {
          <div class="flex flex-col gap-y-5">
            <div class="flex items-center gap-x-10" [formGroupName]="i">
              <div class="flex">
                <mat-button-toggle-group formControlName="type" name="Trade Action" aria-label="Trade Action" class="button-toggle-group">
                  @if (requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.contribution) {
                    <mat-button-toggle class="is-primary"
                      [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                    value={{Buy}}>BUY</mat-button-toggle>
                  }
                  @if (requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.distribution) {
                    <mat-button-toggle class="is-alert"
                      [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                    value={{Sell}}>SELL</mat-button-toggle>
                  }
                </mat-button-toggle-group>
              </div>
              <mat-select class="select text-sm" formControlName="action" placeholder="Select an action"
                (selectionChange)="updateCustomHoldingValidation(item)">
                @for (option of getTradeActionOptions(item); track option) {
                  <mat-option [value]="option">{{option.name}}</mat-option>
                }
              </mat-select>
              <input type="text" class="text-sm input w-96" formControlName="stockSymbol" placeholder="Stock symbol" />
              @if (item.value.action.allocationAmountType === Dollar) {
                <input currencyMask type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
              }
              @if (item.value.action.allocationAmountType !== Dollar) {
                <input currencyMask [options]="customHoldingShareAmountMaskOptions" type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
              }
              <div class="w-34">
                <button type="button" class="icon-button is-warning is-inverted"
                  matTooltip="Remove entry" (click)="removeCustomAllocation(i)">
                  <mat-icon svgIcon="close"></mat-icon>
                </button>
              </div>
            </div>
            @if (item.invalid && item.touched) {
              <div
              class="text-xs text-red w-full">Fields are required</div>
            }
          </div>
        }
        <!-- NEW HOLDING -->
        <form class="flex items-center gap-x-10" [formGroup]="newCustomHolding!" (ngSubmit)="addNewCustomAllocation()">
          <div class="flex">
            <mat-button-toggle-group formControlName="type" name="Trade Action" aria-label="Trade Action" class="button-toggle-group">
              @if (requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.contribution) {
                <mat-button-toggle class="is-primary"
                  [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                value={{Buy}}>BUY</mat-button-toggle>
              }
              @if (requestType === requestTypeEnum.reallocation || requestType === requestTypeEnum.distribution) {
                <mat-button-toggle class="is-alert"
                  [ngClass]="{'w-1/2': requestType === requestTypeEnum.reallocation}"
                value={{Sell}}>SELL</mat-button-toggle>
              }
            </mat-button-toggle-group>
          </div>
          <mat-select class="select text-sm" formControlName="action" placeholder="Select an action"
            (selectionChange)="updateCustomHoldingValidation(newCustomHolding!)">
            @for (option of getTradeActionOptions(newCustomHolding!); track option) {
              <mat-option [value]="option">{{option.name}}</mat-option>
            }
          </mat-select>
          <input type="text" class="text-sm input w-96" formControlName="stockSymbol" placeholder="Stock symbol" />
          @if (newCustomHolding?.value?.action?.allocationAmountType === Dollar) {
            <input currencyMask type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
          }
          @if (newCustomHolding?.value?.action?.allocationAmountType !== Dollar) {
            <input currencyMask [options]="customHoldingShareAmountMaskOptions"type="text" class="text-sm input w-96" formControlName="amount" placeholder="Amount" />
          }
          <div class="min-w-34">
            @if (newCustomHolding?.valid && newCustomHolding?.touched) {
              <button type="submit" class="icon-button is-primary is-outlined" [ngClass]="{'animate-pulse bg-blue-dark text-white' : newCustomHolding?.valid}" matTooltip="Add another entry">
                <mat-icon>add</mat-icon>
              </button>
            }
          </div>
        </form>
        @if (newCustomHolding?.invalid && newCustomHolding?.touched) {
          <div
            class="text-xs text-red w-full -mt-5">Fields are required
          </div>
        }
      </div>
    }
  </section>
}