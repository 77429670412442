@if (solicitationFormGroup) {
  <div class="flex flex-col" [formGroup]="solicitationFormGroup">
    <label class="text-base font-bold text-gray-dark required-label mb-15">Who initiated this request?</label>
    <mat-radio-group formControlName="isSolicited" class="flex flex-col gap-y-10 pl-5">
      @for (item of solicitationOptions; track item) {
        <mat-radio-button [value]="item.value"><span class="pl-5">{{item.name}}</span></mat-radio-button>
      }
    </mat-radio-group>
    @if (solicitationFormGroup.controls['isSolicited'].touched && solicitationFormGroup.controls['isSolicited'].errors) {
      <span class="w-full text-xs mt-5 text-red">
        @if (solicitationFormGroup.controls['isSolicited'].errors['required']) {
          <span>This field is required.</span>
        }
      </span>
    }
  </div>
}