<div class="relative flex-shrink-0 w-full min-h-384 card">
  <div>
    <h4 class="text-2xl font-normal leading-tight text-center m-30">{{!formSubmitted ? 'Password Reset' : 'Reset link sent to ' + userNameForm.value.userNameOrEmail}}</h4>
  </div>
  @if (!formSubmitted) {
    <form class="flex flex-col flex-shrink-0 my-10" [formGroup]="userNameForm" (submit)="onSubmit()">
      <div class="p-10 mx-32 my-10 text-lg rounded md:p-20 text-gray-dark bg-blue-lightest">
        <p class>Please enter your username or email address.</p>
      </div>
      <div class="flex flex-col flex-shrink-0 mx-32 my-10">
        <label class="mb-5 label">Username or Email</label>
        <input type="text" name="userNameOrEmail" formControlName="userNameOrEmail" autofocus class="input is-lg">
        @if (formSubmitting && userNameForm.invalid) {
          <span class="text-sm mt-5 text-red">
            @if (userNameForm.controls.userNameOrEmail.hasError('required')) {
              <span>This field is required</span>
            }
          </span>
        }
        <div class="flex flex-col flex-none my-30">
          <button class="text-base button is-lg is-primary focus:ring-0" mat-button type="submit">Submit</button>
        </div>
      </div>
    </form>
  }
  @else {
    <div class="flex flex-col justify-center mb-10">
      <div class="p-10 mx-32 my-10 text-lg rounded md:p-20 text-gray-dark bg-blue-lightest">
        <p>If the username or email you entered was correct, you will receive an email containing a password reset link shortly.</p>
      </div>
      <button class="text-base mx-32 button my-30 is-lg is-primary is-outlined focus:ring-0" mat-button routerLink="login">
        Back to Sign In
      </button>
    </div>
  }
</div>