@if (parentForm) {
  <section class="grid grid-cols-2 gap-x-20 gap-y-30" [formGroup]="parentForm">
    <p class="col-span-2 text-lg">Enter a few details to complete your planning desk request.</p>
    @if (advisorList) {
      <div class="flex flex-col col-span-2 xs:col-span-1">
        <label class="mb-20 font-bold">Who is initiating this request? <sup class="text-red">*</sup></label>
        <mat-select formControlName="agent" class="select" placeholder="Please select an Advisor">
          @for (agent of advisorList; track agent) {
            <mat-option [value]="agent">{{agent.firstName}} {{agent.lastName}}</mat-option>
          }
        </mat-select>
        @if (parentForm.controls.agent.errors && parentForm.controls.agent.touched) {
          <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red">
            @if (parentForm.controls.agent.hasError('required')) {
              <span>This field is required.</span>
            }
          </span>
        }
      </div>
    }
    <div class="flex flex-col col-span-2 xs:col-span-1">
      <label class="mb-20 font-bold">What is the client's name? <sup class="text-red">*</sup></label>
      <input type="text" class="input" formControlName="client" placeholder="Please enter client's full name" />
      @if (parentForm.controls.client.errors && parentForm.controls.client.touched) {
        <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red">
          @if (parentForm.controls.client.hasError('required')) {
            <span>This field is required.</span>
          }
        </span>
      }
    </div>
    <div class="flex flex-col col-span-2 xs:col-span-1">
      <label class="mb-20 font-bold">When is the next appointment? <sup class="text-red">*</sup></label>
      <div class="datepicker-input-container">
        <input [matDatepicker]="picker" class="datepicker-input" formControlName="nextAppointmentDate" (click)="picker.open()" readonly
          placeholder="mm/dd/yyyy"  [min]="minStartDate">
          <mat-icon class="datepicker-icon" svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
          <mat-datepicker #picker color="accent"></mat-datepicker>
        </div>
        @if (parentForm.controls.nextAppointmentDate.errors && parentForm.controls.nextAppointmentDate.touched) {
          <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red">
            @if (parentForm.controls.nextAppointmentDate.hasError('required')) {
              <span>This field is required.</span>
            }
          </span>
        }
      </div>
      <div class="flex flex-col col-span-2">
        <label class="mb-20 font-bold">What type of request is this? <sup class="text-red">*</sup></label>
        <mat-radio-group formControlName="requestType" class="flex flex-wrap gap-x-30 gap-y-10">
          @for (option of planningDeskRequestType; track option) {
            <mat-radio-button class="flex-none" [value]="option">{{option.name}}</mat-radio-button>
          }
        </mat-radio-group>
      </div>
      @if (parentForm.controls.recommendationType.enabled) {
        <div class="flex flex-col col-span-2">
          <label class="mb-20 font-bold">Who is providing the recommendation? <sup class="text-red">*</sup></label>
          <mat-button-toggle-group formControlName="recommendationType" class="mr-auto button-toggle-group">
            @for (option of recommendationType; track option) {
              <mat-button-toggle [value]="option">{{option.name}}</mat-button-toggle>
            }
          </mat-button-toggle-group>
        </div>
      }
      <div class="flex flex-col col-span-2">
        <label class="mb-20 font-bold">Tell us what you would like to see 
          @if (parentForm.controls.notes.hasError('required')) {
            <sup class="text-red">*</sup>
          }
        </label>
        <textarea formControlName="notes" rows="6" class="textarea" placeholder="Enter your notes here"></textarea>
        @if (parentForm.controls.notes.errors && parentForm.controls.notes.touched) {
          <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red">
            @if (parentForm.controls.notes.hasError('required')) {
              <span>This field is required.</span>
            }
          </span>
        }
      </div>
    </section>
  }