<form class="flex flex-col flex-shrink-0 my-10 gap-y-10 p-20 w-full" [formGroup]="newPasswordForm" (submit)="onSubmit()">
  <div class="flex flex-col">
    <h4 class="text-2xl font-normal leading-tight text-center">Reset Password</h4>
  </div>
  <div class="my-10 p-10 md:p-20 text-gray-dark text-lg  bg-blue-lightest rounded">
    <p>Please enter your new password. To confirm your identity, a 6-digit pin will be sent to the primary SMS device registered to this account.</p>
  </div>
  <div class="w-full flex flex-col items-center justify-center gap-y-20 mb-15">
    <div class="w-full">
      <label class="mb-5 label">New Password</label>
      <div class="flex flex-row items-center">
        <input [type]="hideNewPassword ? 'password' : 'test'" name="newPassword" formControlName="newPassword" autofocus class="input w-full is-lg">
        <mat-icon class="-ml-50" (click)="hideNewPassword = !hideNewPassword">{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </div>
      <div class="h-10">
        @if (newPasswordForm.controls.newPassword.touched && newPasswordForm.controls.newPassword.errors) {
          <span class="w-full text-xs text-red"
            >
            @if (newPasswordForm.controls.newPassword.hasError('minlength')) {
              <span>{{passwordMinLengthError}}</span>
            }
            @if (newPasswordForm.controls.newPassword.hasError('maxlength')) {
              <span>{{passwordMaxLengthError}}</span>
            }
          </span>
        }
      </div>
    </div>
    <div class="w-full">
      <label class="mb-5 label">Confirm Password</label>
      <div class="flex flex-row items-center">
        <input [type]="hideConfirmPassword ? 'password' : 'test'" name="userNameOrEmail" formControlName="confirmPassword" autofocus class="input w-full is-lg">
        <mat-icon class="-ml-50" (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
      </div>
      <div class="h-10">
        @if (newPasswordForm.controls.confirmPassword.touched && newPasswordForm.controls.confirmPassword.errors) {
          <span class="w-full text-xs text-red">
            @if (newPasswordForm.controls.confirmPassword.hasError('minlength')) {
              <span>{{passwordMinLengthError}}</span>
            }
            @if (newPasswordForm.controls.confirmPassword.hasError('maxlength')) {
              <span>{{passwordMaxLengthError}}</span>
            }
          </span>
        }
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-y-10">
    <button [disabled]="newPasswordForm.controls.newPassword.errors || newPasswordForm.controls.confirmPassword.errors || passwordMisMatchError ||  isSubmitting" class="text-base button is-lg is-primary focus:ring-0" mat-button type="submit">Submit</button>
    @if (passwordMisMatchError) {
      <span class="p-10 border-2 border-red flex text-sm justify-center text-red bg-red-lightest">Passwords do not match</span>
    }
    @if (apiError) {
      <span class="p-10 border-2 border-red flex text-sm justify-center text-red bg-red-lightest">An error occured, please try again. If this error continues, please contact your advisor services manager.</span>
    }
  </div>
</form>