@if (parentForm) {
  <section class="flex flex-col gap-x-20 gap-y-30" [formGroup]="parentForm">
    <div class="flex flex-col">
      <label class="mb-20 font-bold">Tell us about the client's current asset <sup class="text-red">*</sup></label>
      <div class="flex flex-col gap-y-10" formArrayName="assetBreakdown">
        <div class="flex text-sm gap-x-10 text-gray">
          <span class="flex-none w-220">Account Type</span>
          <span class="flex-grow">Current Value</span>
          <span class="flex-none w-96">Client Fee</span>
          <span class="flex-none w-34"></span>
        </div>
        @for (item of parentForm.controls.assetBreakdown.controls; track item; let i = $index) {
          <div class="flex flex-col gap-y-5" [formGroupName]="i">
            <div class="flex gap-x-10">
              <mat-select class="flex-none select w-220" formControlName="accountType" placeholder="Select an account type">
                @for (option of accountTypeList; track option) {
                  <mat-option [value]="option">{{option.name}}</mat-option>
                }
              </mat-select>
              <input currencyMask type="text" class="flex-grow input" name="currentValue" autocomplete="off" formControlName="currentValue" placeholder="Ex: $100,000.00" />
              <input currencyMask [options]="clientFeeMaskOptions" type="text" autocomplete="off" class="flex-none input w-96" name="clientFee" formControlName="clientFee" placeholder="Ex: 1.25%" />
              <button type="button" class="button icon-button is-warning is-inverted is-outlined" [disabled]="parentForm.controls.assetBreakdown.length === 1" matTooltip="Remove entry" (click)="removeAssetEntry(i)">
                <mat-icon class="mi-24" svgIcon="close"></mat-icon>
              </button>
              <button type="button" mat-button class="button icon-button is-primary is-inverted is-outlined" (click)="addNewAssetEntry()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
            @if (item.touched && item.invalid) {
              <span class="flex flex-col text-xs font-medium gap-y-5 text-red">
                <span>Please fill out all entries this row.</span>
              </span>
            }
          </div>
        }
      </div>
      @if (parentForm.controls.assetBreakdown.touched) {
        <span class="flex flex-col text-xs font-medium gap-y-5 text-red">
          @if (parentForm.controls.assetBreakdown.hasError('required')) {
            <span>This field is required.</span>
          }
          @if (parentForm.controls.assetBreakdown.hasError('pattern')) {
            <span>This field is required.</span>
          }
        </span>
      }
    </div>
    <div class="flex flex-col">
      <label class="mb-20 font-bold">Please upload any required documents below <sup class="text-red">*</sup></label>
      <app-file-dropper (fileChangeEvent)="uploadFiles($event)" [value]="files"></app-file-dropper>
      @if (parentForm.controls.files.touched) {
        <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red">
          @if (parentForm.controls.files.hasError('required')) {
            <span>This field is required.</span>
          }
        </span>
      }
    </div>
    <div class="flex flex-col">
      <label class="mb-20 font-bold">What type of report would you like? <sup class="text-red">*</sup></label>
      <mat-select class="select" formControlName="analysisReportType" [multiple]="true" placeholder="Select at least one report type">
        @for (option of analysisReportTypeList; track option) {
          <mat-option [value]="option">{{option.name}}</mat-option>
        }
      </mat-select>
      @if (parentForm.controls.analysisReportType.touched) {
        <span class="flex flex-col mt-5 text-xs font-medium gap-y-5 text-red">
          @if (parentForm.controls.analysisReportType.hasError('required')) {
            <span>This field is required.</span>
          }
        </span>
      }
      @if (parentForm.controls.analysisReportTypeOther.enabled) {
        <div class="flex flex-col w-1/2 mt-10 gap-y-5">
          <input type="text" class="input" name="analysisReportTypeOther" formControlName="analysisReportTypeOther" placeholder="Enter custom report type" />
          @if (parentForm.controls.analysisReportTypeOther.touched) {
            <span class="flex flex-col text-xs font-medium gap-y-5 text-red">
              @if (parentForm.controls.analysisReportTypeOther.hasError('required')) {
                <span>This field is required.</span>
              }
              @if (parentForm.controls.analysisReportTypeOther.hasError('pattern')) {
                <span>This field is required.</span>
              }
            </span>
          }
        </div>
      }
    </div>
  </section>
}