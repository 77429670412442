@if (!isLoading) {
  <form @fadeIn [formGroup]="planningDeskRequestFormGroup" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
    <header>
      <div class="m-10 flex flex-col">
        <h2 class="text-lg font-bold mr-20 leading-tight">Planning Desk Request - Step {{currentStep}}/{{pages.length}}</h2>
      </div>
      <button [disabled]="isSubmitting" mat-button
        class="m-10 ml-auto icon-button is-primary is-inverted"
        type="button" (click)="cancelRequest()">
        <mat-icon svgIcon="close"></mat-icon>
      </button>
    </header>
    @if (isSubmitting) {
      <div class="flex-grow w-full loader-wrap" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    }
    @if (!isSubmitting && !isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto p-30">
        @if (currentStep === accountUpdateStepEnum.step1) {
          <app-general-info-step [parentForm]="generalInfoFormGroup" [advisorList]="advisorList"></app-general-info-step>
        }
        @else if (currentStep === accountUpdateStepEnum.step2) {
          <app-asset-details-step [parentForm]="assetDetailsFormGroup"></app-asset-details-step>
        }
        @else if (currentStep === accountUpdateStepEnum.step3) {
          <app-planning-desk-review-step [parentForm]="planningDeskRequestFormGroup"></app-planning-desk-review-step>
        }
      </section>
    }
    @if (!isSubmitting && isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.planningDesk"></app-complete>
      </section>
    }
    @if (!isComplete) {
      <footer>
        @if (currentStep === accountUpdateStepEnum.step1) {
          <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            (click)="cancelRequest()"
          >Cancel</button>
        }
        @if (currentStep > accountUpdateStepEnum.step1) {
          <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
          (click)="previousStep(); isSubmitted=false;">Back</button>
        }
        @if ((currentStep < pages.length)) {
          <button [disabled]="isSubmitting" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
            (click)="nextStep(pages[currentStep - 1].formGroup)"
          >Next</button>
        }
        @if (currentStep === accountUpdateStepEnum.step3) {
          <button [disabled]="isSubmitting" type="submit" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
          >{{isSubmitting ? "Submitting" : "Submit"}}</button>
        }
      </footer>
    }
  </form>
}
@else {
  <div @fadeIn class="flex-grow w-full loader-wrap">
    <div class="loader-sm is-blue"></div>
  </div>
}