<form @fadeIn [formGroup]="advisorMessageFormGroup" class="flex flex-col w-full h-full" (ngSubmit)="onSubmit()">
  <header>
    <div class="flex-col">
      <div class="m-10 mr-20 text-xl font-bold">New Uploads & Message</div>
      <div class="ml-10 mr-20 text-base font-bold">{{this.data ? this.data.onBehalfOfRequest?.requestTitle : ''}}</div>
    </div>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" tabindex="-1" type="button" mat-dialog-close [disabled]="isSubmitting">
      <mat-icon>close</mat-icon>
    </button>
  </header>
  @if (isSubmitting && !isSubmitted) {
    <div class="flex-grow w-full loader-wrap" @fadeIn>
      <div class="loader-sm is-blue"></div>
    </div>
  }
  @if (!isSubmitting && isSubmitted) {
    <section class="">
      @if (isComplete === true) {
        <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-500">
          <span class="material-icons mi-96 text-green-dark">task_alt</span>
          <label class="font-bold">Thank You!</label>
          <label class="font-semibold">Your new upload/message request has been submitted successfully.</label>
          <button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
          (click)="closeRequest()">Close</button>
        </div>
      }
      @if (isComplete === false) {
        <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-300">
          <span class="material-icons mi-96 text-red-dark">error</span>
          <label class="font-bold">Uh Oh!</label>
          <label class="font-semibold">Something went wrong with submitting your upload/message request.</label>
          <label class="font-semibold">Please Try submitting again.</label>
        </div>
      }
    </section>
  }
  @if (!isSubmitting && !isSubmitted) {
    <section class="dialog-content">
      <!-- Account -->
      @if (this.data ? this.data.onBehalfOfRequest?.requestType !== requestTypeEnum.PlanningDesk : true) {
        <div class="flex flex-col flex-none m-10">
          <label class="mb-5 text-sm text-gray required-label">Select Account</label>
          <input type="text" formControlName="account" [matAutocomplete]='auto' class="input" placeholder="Find an account...">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectAccount($event.option.value)" [displayWith]="displayFn">
            @for (acct of accountsList | async; track acct) {
              <mat-option [value]="acct" class="h-auto py-10">
                <div class="flex flex-col gap-y-5">
                  <div class="flex items-start text-sm font-bold">
                    <span class="flex-grow">{{acct.investmentAccountModel || 'No investment account model'}}</span>
                    <span class="flex-none">{{acct.clients}}</span>
                  </div>
                  <div class="text-xs text-gray">{{acct.custodian || 'No custodian'}} - {{acct.accountNumber || 'No account number'}} - {{acct.investmentAccountType || 'No investment account type'}}</div>
                </div>
              </mat-option>
            }
          </mat-autocomplete>
          @if (advisorMessageFormGroup.controls.account.touched && advisorMessageFormGroup.controls.account.errors) {
            <span class="w-full mt-5 text-xs text-red">
              @if (advisorMessageFormGroup.controls.account.hasError('required')) {
                <span>This field is required.</span>
              }
            </span>
          }
        </div>
      }
      <!-- Recipient -->
      <!--<div class="flex flex-col flex-none m-10">
      <label class="mb-5 text-sm text-gray required-label">Recipient</label>
      <mat-select formControlName="recipient" class="select" placeholder="Who should this message be sent to?">
        <mat-option *ngFor="let recipient of recipientsList" [value]="recipient">
          {{recipient.recipientName}}
        </mat-option>
      </mat-select>
      <span *ngIf="advisorMessageFormGroup.controls.recipient.touched && advisorMessageFormGroup.controls.recipient.errors" class="w-full mt-5 text-xs text-red">
        <span *ngIf="advisorMessageFormGroup.controls.recipient.hasError('required')">This field is required.</span>
      </span>
      </div>-->
      <!-- Message -->
      <div class="flex flex-col flex-none m-10">
        <label class="mb-5 text-sm text-gray required-label">Message</label>
        <textarea class="textarea" formControlName="message" cols="30" rows="3" placeholder="Enter your message here..."></textarea>
        @if (advisorMessageFormGroup.controls.message.touched && advisorMessageFormGroup.controls.message.errors) {
          <span class="w-full mt-5 text-xs text-red">
            @if (advisorMessageFormGroup.controls.message.hasError('required')) {
              <span>A message or upload is required.</span>
            }
          </span>
        }
      </div>
      <!-- Upload -->
      <div class="flex flex-col flex-none m-10">
        <label class="mb-5 text-sm text-gray required-label">Uploads</label>
        <app-file-dropper (fileChangeEvent)="uploadFiles($event)"></app-file-dropper>
        @if (advisorMessageFormGroup.controls.files.touched && advisorMessageFormGroup.controls.files.errors) {
          <span class="w-full mt-5 text-xs text-red absolute top-100 left-0">
            @if (advisorMessageFormGroup.controls.files.hasError('required')) {
              <span>A message or upload is required.</span>
            }
          </span>
        }
      </div>
    </section>
  }
  @if (!isSubmitting && !isComplete) {
    <footer>
      <button mat-button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined" mat-dialog-close [disabled]="isSubmitting">Cancel</button>
      <button mat-button type="submit" class="m-10 ml-auto button is-lg is-primary" [disabled]="isSubmitting || (this.data && this.data.onBehalfOfRequest?.requestType === requestTypeEnum.PlanningDesk ? false : !this.selectedAccount)">
      {{isSubmitting ? "Submitting" : "Submit"}}</button>
    </footer>
  }
</form>