@if (taxFormGroup) {
  <div class="flex flex-col gap-y-10" [formGroup]="taxFormGroup">
    <app-message-notification panelType="alert" [showPanelIcon]=false>
      <div>
        <strong>Please Note</strong>, if you are changing withholdings, please complete the applicable form:
      </div>
      <div class="mt-10">
        <strong>Non-Qualified:</strong>&nbsp;&nbsp;<a target="_blank" href="/api/wistia/download?form=schwabmoneylink">Money Link Form</a>
      </div>
      <div class="mt-5">
        <strong>Qualified:</strong>&nbsp;&nbsp;<a target="_blank" href="/api/wistia/download?form=schwabiradist">IRA Distribtion Form</a>
      </div>
    </app-message-notification>
    <div class="flex flex-row gap-x-10 items-stretch">
      <div class="flex flex-col gap-y-10 w-full">
        <label for="federal-tax-amount" class="text-sm text-gray">Federal Tax Withholding</label>
        <input id="federal-tax-amount" currencyMask [options]="federalTaxMaskOptions" type="text" onfocus="this.select()"
          class="w-full input text-base text-gray-dark" formControlName="federalTaxAmount" placeholder="0.00%" />
      </div>
      <div class="flex flex-col gap-y-10 w-full">
        <label for="state-tax-amount" class="text-sm text-gray">State Tax Withholding</label>
        <input id="state-tax-amount" currencyMask [options]="stateTaxMaskOptions" type="text" onfocus="this.select()"
          class="input text-base text-gray-dark" formControlName="stateTaxAmount" placeholder="0.00%" />
      </div>
    </div>
  </div>
}